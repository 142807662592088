<div class="text-center">
    
        <h1 mat-dialog-title>CONTACTO </h1>
        </div>
        <mat-dialog-content style="font-family: 'Muli'">
        
            
        
               <div class="col-sm-10 m-auto text-left p-1">

                    <p>Si tenés dudas o consultas, te recomendamos comunicarte con nosotros para poder asesorarte mejor.  </p>                 
                </div>
        
                    <div class="col-sm-10 m-auto text-center pb-3">

                            <mat-list role="list">
                                    <mat-list-item role="listitem"><strong>Lunes a Viernes de 9 hs a 17:30 hs.</strong> </mat-list-item>
                                    <!-- <mat-list-item role="listitem"><i class="fas fa-phone mx-1"></i>  (011) 4382-0692</mat-list-item> -->
                                    <mat-list-item role="listitem"><i style="color:green" class="fab fa-whatsapp mx-1"></i>  WhatsApp: 11-3957-0478</mat-list-item>
                              
                                  </mat-list>

                           
                                                                           
              
                      </div>

                      <div class="col-sm-10 m-auto text-left pb-3">
                        <p>Si preferis, podés enviarnos un mail a: </p>
                            <h6>  <strong> <i class="fas fa-envelope"></i>  info@mytfabricantes.com.ar</strong></h6>
                    
                    </div>
        
         
        </mat-dialog-content>
        <mat-dialog-actions align="center">
         
          <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
        </mat-dialog-actions>