<div class="grilla">


    <mat-card id="banner">
  
      </mat-card>


      <mat-card >
        
        <div class="col-sm-6 m-auto text-justify">
        <h5 style="font-family: 'Muli'; line-height: 40px;">
          Somos una empresa familiar con más de 60 años de trayectoria en el sector. <br>
          Todos nuestros productos son fabricados in house; Contamos con un catalogo con más de 600 productos en los materiales Oro 18k, Plata 925 y Plata y Oro. <br>
          
          Actualmente somos proveedores de muchos comercios del sector lo que nos coloca como una de las fábricas más importantes del rubro. <br>
          
          Nuestra metodología de trabajo es simple; Nos apasiona lo que hacemos y es por eso que estamos en constante movimiento para poder ofrecerte las ultimas tendencias con la mejor calidad.
        </h5>
        </div>

      </mat-card>









</div>
