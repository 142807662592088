
<mat-card class="">

    <div class="text-center"> 
  
      <h3>Registra tu comercio</h3>
    </div>
  
    <div class="text-center pt-5">
  
      <h6><strong> Activa el perfil de tu comercio en minutos </strong></h6>
  
      <p>Por favor, brindanos los siguientes datos sobre tu comercio.</p>
    
  
    </div>
  
  
    <div class="col-10 mx-auto text-center">  
  
        <form (ngSubmit)="registroComercio(f)" #f="ngForm" >
          <span *ngIf="!f.valid" class="text-danger">* Completa los campos obligatorios</span>
            <div class="form-row row mx-auto">
              <div class="col-lg-6 col-sm-12 mx-auto ">
                <input type="text"  class="form-control m-1" ngModel name="nombre" placeholder="Nombre de contacto *"  required> 
                <input type="number" class="form-control m-1" ngModel name="tel" placeholder="Telefono *" minlength="6" required>
                <input type="text" matTooltip="Ingresa tu Razon Social" class="form-control m-1" ngModel name="razon"  placeholder="Razon - Nombre del Comercio *" required>
                <input type="email" class="form-control m-1"  ngModel name="mail" placeholder="Email *" email pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$" required>
                <input type="password" class="form-control m-1"  ngModel name="pass" placeholder="Contraseña *" minlength="6" required >
               
               
              </div>
              
              <div class="col-lg-6 col-sm-12 mx-auto">

                <div class=" col-12 mx-auto row">

                  <div class="col-6 mx-auto">

                    <select class="prov form-control my-1 mx-auto" placeholder="Provincia" required ngModel name="provincia" >

                        <option selected disabled  value="">Elegir provincia...</option>  
                        <option  value="Buenos Aires" >Buenos Aires</option>  
                        <option value="C.A.B.A">Capital Federal</option> 
                         <option value="Catamarca">Catamarca</option>       
                         <option value="Chaco">Chaco</option>       
                         <option value="Chubut">Chubut</option>       
                         <option value="Córdoba">Córdoba</option>       
                          <option value="Corrientes">Corrientes</option>      
                          <option value="Entre Ríos">Entre Ríos</option>      
                          <option value="Formosa">Formosa</option>      
                          <option value="Jujuy">Jujuy</option>      
                          <option value="La Pampa">La Pampa</option>       
                          <option value="La Rioja">La Rioja</option>      
                          <option value="Mendoza">Mendoza</option>      
                          <option value="Misiones">Misiones</option>      
                          <option value="Neuquén">Neuquén</option>      
                          <option value="Río Negro">Río Negro</option>      
                          <option value="Salta"> Salta</option>     
                          <option value="San Juan">San Juan</option>      
                          <option value="Santa Cruz">Santa Cruz</option>      
                          <option value="Santa Fe">Santa Fe</option>      
                          <option value="Santiago del Estero"> Santiago del Estero</option>     
                          <option value="Tierra del Fuego">Tierra del Fuego</option>      
                          <option value="Tucumán">Tucumán</option>      
                    </select>
                  </div>
                  <div class="col-6 mx-auto">
                    <input class="prov form-control my-1 mx-auto" placeholder="Localidad" ngModel name="localidad" type="text">
                  </div>
                  </div>
               
                  <div #cuit class="col-12 mx-auto row mt-2">
                  <label class="mt-2" for="cuit"> CUIT/CUIL</label>
                <input  list="partA" maxlength="2" matTooltip="20, 23, 27, 30 o 33" class="cuitA form-control m-auto" ngModel name="partA"  placeholder="20 *" pattern="(20|23|27|30|33)" required> 
                <datalist id="partA">
                    <option value="20">
                    <option value="23">
                    <option value="27">
                    <option value="30" selected>
                    <option value="33">
                    
                  </datalist>
                <input type="number"  matTooltip="Ingresa 8 digitos sin guiones" max="99999999" min="1000000"  class="cuit form-control m-auto" ngModel name="dni"  placeholder="70970959 *" required> 
                <input type="number"  class="cuitD form-control m-auto " pattern="(0|1|2|3|4|5|6|7|8|9)"  maxlength="1" min="0" max="9" ngModel name="partB"  matTooltip="Ingresa un numero del 0-9" placeholder="7 *" required>
                </div>
               
               


                <div #dir class="col mx-auto row mt-2">
                  <label class="mt-1 mx-auto" for="dir"> Direccion</label>
                <input type="text" class="dir form-control m-auto" ngModel name="calle" placeholder="Calle *"  required> 
                <input type="number" class="dirA form-control m-auto " ngModel name="altura" placeholder="Altura *" required> 
                <input type="text" class="dirA form-control m-auto " ngModel name="piso" placeholder="Piso/Of" > 
                <input type="text" class="dirA form-control m-auto " ngModel name="cp" placeholder="C.P *" required> 

              </div>
                
  
              </div>
  
            </div>
           
            
            <div class="col-12 text-center p-3">

            <button mat-raised-button  color="primary"  type="submit" [disabled]="!f.valid" > Registrar </button>
          </div>
          </form>
 
  
    </div>
  
  
  
  
  
  </mat-card>