<!-- <mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer  class="sidenav" 
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'dialog'"
      [mode]="(isHandset$ | async) ? 'side' : 'side'"
      [opened]="!(isHandset$ | async)" >
    <mat-toolbar id="logo"  > <a [routerLink]="['/home']"><img src="assets/logo.png" width="80px" height="80px" alt=""> </a > </mat-toolbar>
    <mat-nav-list  >
      <a  mat-list-item  [routerLink]="['/productos']" style="font-family: 'Muli'" >Productos</a>
      <a mat-list-item [routerLink]="['/nosotros']"   style="font-family: 'Muli'"  >Nosotros</a>
      <a mat-list-item *ngIf="!userService.isLoggedIn"  [routerLink]="['/registro']"  style="font-family: 'Muli'"    >Registrate</a>
      <a mat-list-item   style="font-family: 'Muli'" (click)=" openDialogContacto()" >Contacto</a>
      <a mat-list-item   style="font-family: 'Muli'" (click)="openDialogAyuda()"  >¿Como comprar?</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <mat-toolbar  color="primary">
      <button
      *ngIf="!drawer.opened"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
       >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <button
      *ngIf="drawer.opened"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
       >
        <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
      </button>

       <span *ngIf="!drawer.opened"><a [routerLink]="['/home']" style="text-decoration-line: none; color: white; " > M&T
      </a></span>
     <a *ngIf="userService.isLoggedIn == false" [routerLink]="['/login']" 
      id="ingresar" mat-icon-button> <mat-icon style="font-size: 24px;">account_circle</mat-icon> <small>INGRESAR</small>  </a>
      
     <button *ngIf="userService.isLoggedIn && userService.userInfo"  id="menuUsuario" mat-button [matMenuTriggerFor]="menu"> <i class="material-icons">
      person_pin
      </i > <i *ngIf="userService.userInfo" class="animated fadeIn" > {{(userService.userInfo.razon)? userService.userInfo.razon:'' }} </i> 
                  
       <i style="font-size:12px;" class="material-icons">
        keyboard_arrow_down
        </i> </button>
    
     <mat-menu #menu="matMenu">
      <button mat-menu-item  [routerLink]="['user',userService.userInfo.uid ]" *ngIf=" userService.isLoggedIn && userService.userInfo">Mi perfil</button>
    
      <button mat-menu-item [routerLink]="['/login']"  (click)="userService.SignOut()" >Salir  <i  class="fas fa-sign-out-alt"></i></button>
    
    </mat-menu>

    </mat-toolbar>
    
<router-outlet></router-outlet>

<div id="top">
<button (click)=" up()"  mat-mini-fab ><mat-icon>arrow_upward</mat-icon></button>

</div>
  </mat-sidenav-content>
</mat-sidenav-container> -->


<mat-drawer-container class="sidenav-container">
  <mat-drawer #drawer fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'dialog'"
      [mode]="(isHandset$ | async) ? 'side' : 'side'"
      [opened]="!(isHandset$ | async)" >
    <mat-toolbar id="logo"  > <a [routerLink]="['/home']"><img src="assets/logo.png" width="80px" height="80px" alt=""> </a > </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item  [routerLink]="['tienda/productos']" routerLinkActive="active" style="font-family: 'Muli'" >Productos</a>
      <a mat-list-item  [routerLink]="['/imagenes']" routerLinkActive="active" style="font-family: 'Muli'" >Imagenes</a>
      <a mat-list-item  [routerLink]="['/nosotros']" routerLinkActive="active"  style="font-family: 'Muli'"  >Nosotros</a>
      <a mat-list-item  *ngIf="!userService.isLoggedIn()" routerLinkActive="active"  [routerLink]="['/registro']"  style="font-family: 'Muli'" >Registrate</a>
      <a mat-list-item  style="font-family: 'Muli'" (click)=" openDialogContacto()" >Contacto</a>
      <a mat-list-item  style="font-family: 'Muli'" (click)="openDialogAyuda()"  >¿Como comprar?</a>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="content">
    <mat-toolbar color="primary" class="nav">
      <div style="display: flex;align-items: center;justify-content: flex-start;">
      <button
      *ngIf="!drawer.opened"
        type="button"
        aria-label="abrir"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="abrir">menu</mat-icon>
      </button>
      <button
      *ngIf="drawer.opened"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
       >
        <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
      </button>
       <span *ngIf="!drawer.opened"><a [routerLink]="['/home']" style="text-decoration-line: none; color: white; " >
        <img src="/assets/logo.png" width="50px" height="50px" alt="">
      </a></span>
      </div>

       <button mat-button *ngIf="!userService.isLoggedIn()" 
        [routerLink]="['/login']" > <mat-icon >account_circle</mat-icon> <small>INGRESAR</small> 
       </button>
     
      
     <button *ngIf="userService.isLoggedIn() && userService.userInfo" mat-button [matMenuTriggerFor]="menu"> <i class="material-icons">
      person_pin
      </i > <i *ngIf="userService.userInfo" class="animated fadeIn" > {{(userService.userInfo.razon)? userService.userInfo.razon:'' }} </i> 
                  
       <i style="font-size:12px;" class="material-icons">
        keyboard_arrow_down
        </i> </button>
    
     <mat-menu #menu="matMenu">
      <button mat-menu-item  [routerLink]="['/usuario']" *ngIf="userService.isLoggedIn() && userService.userData">Mi perfil</button>
    
      <button mat-menu-item [routerLink]="['/login']"  (click)="userService.SignOut()" >Salir  <i  class="fas fa-sign-out-alt"></i></button>
    
    </mat-menu>

    </mat-toolbar>
    
<router-outlet></router-outlet>
<div id="top">
  <button (click)=" up()"  mat-mini-fab >
    <mat-icon>arrow_upward</mat-icon>
  </button>
  </div>


  </mat-drawer-content>
</mat-drawer-container>