import { Injectable } from '@angular/core';
import { Firestore, collection, query, where, orderBy, collectionData, doc,
  limit, docData, collectionSnapshots } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {


  productos: [];
  imagenBase:Array<any> = [];

  constructor(private db: Firestore) {
this.getImgBase().subscribe((data) => {
  this.imagenBase = [];
  data.forEach((imagen) => {
    if(imagen.fotoPlata){
      this.imagenBase.push({
        data:imagen
      })
    }
    
  })
  // sort by name
  this.imagenBase.sort((a, b) => a.data.nombre.localeCompare(b.data.nombre));

})

  }



  public getImgBase()  {
    const ref = collection(this.db, 'imagenes');
    return collectionData(ref);
}


  public getProductos()  {
    const ref = query(collection(this.db, 'productos'), where('foto1', '>' ,  '' ), limit(20));
    return collectionSnapshots(ref);
}

public getProductosCat(cat:string)  {
  const ref = query(collection(this.db, 'productos'), where('foto1', '>' ,  '' ), where('categoria', '==' ,  cat ));
  return collectionSnapshots(ref);
}

public getProducto( documentKey )  {
  const ref = doc(this.db, 'productos/' + documentKey);
  return docData(ref);
}

// Filtros
public getProductosCategoria(filtro)  {
  const ref = query(collection(this.db, 'productos'), where('categoria', '==' ,  filtro ));
  return collectionData(ref);
}

prodCatMet(cat, met){
  const ref = query(collection(this.db, 'productos'), where('categoria', '==' ,  cat ), where('material.0', '==', met ));
  return collectionData(ref);
}

public getProductosMaterial(filtro)  {
  const ref = query(collection(this.db, 'productos'), where('material.0', '==' ,  filtro ));
  return collectionData(ref);

}

getImagenes(pid:string){
  const ref = query(collection(this.db, 'productos',pid ,'imagenes'));
  return collectionData(ref);
}




}










// tslint:disable-next-line:class-name
export interface producto {

  id: string;
  categoria: string;
  nombre: string;
  codigo: number;
  peso: number;
  material: string;
  medida: number;
  descripcion: string;
  p1: number;
  p2: number;
  p3: number;
  stock: number;
  terminacion: string;
  foto1?: string;
  foto2?: string;
  foto3?: string;


}