
<div class="grid-container">

  <mat-card id="banner" class="animated fadeIn" >
    
  </mat-card>

  <mat-card>
      <div class="container-fluid ">
          <div class=" titulo" >
              <h2 class="text-center">Tenemos todo lo que necesitas para tu negocio</h2>
          </div>
          
          <div class="col-12 row m-auto">
          <!-- Box 1 -->
              <mat-card class=" box animated fadeIn delay-1s">
                  <div class="icono">
                      <i class="fas fa-home fa-2x " > </i>
                  </div>
                  <div class="titulobox m-auto"> <h2 class="text-center">Fabrica in House</h2></div>
                  <div class="parrafo"> <p class="text-center">Fabricamos todo el catalogo de productos disponible.Plata y Oro, Oro 18K, Plata 925 </p> </div>
      
              </mat-card>
              
          
      
              <!-- Fin Box 1 -->
              
          <!-- Box 2 -->
          
                  <mat-card class=" box animated fadeIn delay-2s">
                      <div class="icono">
                          <i class="fas fa-map-marked fa-2x" > </i>
                      </div>
                      <div class=" titulobox m-auto"> <h2 class="text-center">Oficina Comercial</h2></div>
                      <div class="parrafo"> <p class="text-center">Nuestra oficina comercial se encuentra ubicada en pleno microcentro porteño.</p> </div>
          
                    </mat-card>
                  
          
          
                  <!-- Fin Box 2 -->
      
              <!-- Box 3 -->
              
              <mat-card class=" box animated fadeIn delay-3s">
                          <div class="icono">
                              <i class="fas fa-box-open fa-2x" > </i>
                          </div>
                          <div class="titulobox m-auto"> <h2 class="text-center">Envios Personalizados</h2></div>
                          <div class="parrafo"> <p class="text-center">Realizamos envios a todo el pais. Trabajamos con todas las plataformas de envio disponibles.</p> </div>
              
                        </mat-card>
                      
                 
              
                      <!-- Fin Box 3 -->
      </div>
      
      
      
      
      </div>
      
  </mat-card>





</div>