import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor( public userService: UserService) { }

  ngOnInit() {
  }

  signIn(from:NgForm){
    const f = from.value;
    this.userService.SignIn(f.mail,f.pass)
  }


  recuperarPass(){
   let mail= prompt('Ingrese el email con el que se registro para recuperar su clave',)
    
   if(mail == null || mail == ""){
     console.log('cerrado');

   }else{
    this.userService.ForgotPassword(mail) 
    
   }

  }

}
