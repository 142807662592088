import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Firebase


import { CompartidoModule } from '../compartido/compartido.module';
import { MaterialModule } from '../compartido/material/material.module';
import { AppRoutingModule } from '../app-routing.module';
import { UsuarioModule } from '../usuario/usuario.module';
import { ProductoModule } from '../producto/producto.module';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompartidoModule,
 
 
  ],
  exports:[
    CompartidoModule,
    

  ],
  providers:[

  ]
})
export class CoreModule { }
