<div class="wrapImg">
<h2>Catalogo de Imagenes</h2>
<p>Encontrá todas las imágenes disponibles para medallas. 
   Podes chequear la disponibilidad de líneas para cada imagen en nuestros productos.
</p>



<div class="imgContenedor">
   <div class="imagen" *ngFor="let item of _prod.imagenBase">
      <img [src]="item.data.fotoPlata" [alt]="item.data.nombre">
      <h6>{{item.data.nombre}} </h6>
   </div>

</div>
</div>