<mat-card>
  

    <div class="container col-4 p-4 m-auto">
        <div class="text-center">
        <div class="text-center">
        <h3>Gracias por Registrarte en Moro & Tedeschini.</h3>
        <div class="formGroup" *ngIf="userService.userData as user">
        <p class="text-center">En la barra de navegacion encontraras las opciones que buscas.</p>
        <p class="text-center">Si tienes algun inconveniente puedes contactarnos por el medio que eligas de la solapa "Contacto".</p>
       
        <!-- <p>Una vez verificado tu email, vuelve e ingresá con tu email y contraseña.</p> -->
        </div>
        <!-- Calling SendVerificationMail() method using authService Api -->
        <div class="form-group">
        <!-- <button mat-button type="button" class="btn btn-primary" (click)="userService.SendVerificationMail()">
        <i class="fas fa-redo-alt"></i>
        Reenviar email de verificacion 
        </button> -->
        </div>
        </div>
        <div class="text-center">
        <button mat-raised-button color='primary' [routerLink]="['/productos']"> Ver productos</button>
        </div>
        </div>
        </div>
        
    
        
    </mat-card>
