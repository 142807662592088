import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-verificar-email',
  templateUrl: './verificar-email.component.html',
  styles: []
})
export class VerificarEmailComponent implements OnInit {

  constructor(public userService:UserService) { }

  ngOnInit() {
  }

}
