import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';






import { NavbarComponent, Ayuda, Contacto } from './navbar/navbar.component';

import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { RegistroComponent } from './registro/registro.component';
import { LoginComponent } from './login/login.component';


import { PipesPipe } from '../services/pipes.pipe';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { VerificarEmailComponent } from './registro/verificar-email.component';
import { ImagenesComponent } from './imagenes/imagenes.component';





@NgModule({
    declarations: [
        NavbarComponent,
        HomeComponent,
        RegistroComponent,
        LoginComponent,
        Ayuda,
        PipesPipe,
        NosotrosComponent,
        VerificarEmailComponent,
        Contacto,        
        ImagenesComponent,
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        NavbarComponent,        
    ],
    providers: []
})
export class CompartidoModule { }
