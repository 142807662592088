<div class="text-center">
    
<h1 mat-dialog-title>CÓMO COMPRAR </h1>
</div>
<mat-dialog-content style="font-family: 'Muli'">

    

       <div class="col-sm-10 m-auto text-left p-3">
        <p>Comprar en M&T es muy fácil y seguro. A continuación, te brindamos algunos datos importantes que pueden ayudarte al momento de realizar tu compra. Recordá que también podemos despejar tus dudas enviando un WhatsApp al <strong>11-3957-0478</strong>.  </p>
            </div>

            <div class="col-sm-10 m-auto text-left p-3">

                <ul>    

                      <li>  Primero tenes que registrarte en nuestro sitio.</li>
                       <li> Ahora sí, vamos a encontrar el producto que estás buscando: </li>
                       <li> Utilizá la barra de filtros, localizada en la parte superior de la pagina de productos para explorar nuestras categorias y materiales. </li>
                       <li> Si ya tenés en mente un modelo específico podés realizar la búsqueda ingresando la palabra clave en el cuadro de búsqueda.</li>
                       <li> Cliquea "VER" en el Producto que te gusta para poder acceder a los detalles. </li>
                       <li> Si querés comprarlo, elegí el tamaño y agrégalo a tu carro de compras.</li>
                        <li>Luego de agregar un producto al carro de compras es posible continuar comprando y adicionar otros productos.</li>
                      <li>  Finalmente, queda confirmar la compra y aguardar que nos comuniquemos con vos para coordinar la forma de pago y el envío.</li>
                        



                </ul>
      
              </div>

 
</mat-dialog-content>
<mat-dialog-actions align="center">
 
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>