
<mat-card >
  

    <div id="log" class=" container-fluid col-md-10 p-4 m-auto " >
      <div class=" m-auto text-center" >
        <h3 class="p-2">Ingreso exclusivo de Comercios Registrados</h3>
      <form #login="ngForm" id="form" (ngSubmit)="signIn(login)" class=" m-auto text-center">
      <div class="form-group">
      <input type="text" class="form-control" placeholder="Email" ngModel name="mail" required>
      </div>
      <div class="form-group">
      <input type="password" class="form-control" placeholder="Contraseña" ngModel name="pass" required>
      </div>
      <!-- Calling SignIn Api from AuthService -->
      <div class="formGroup">
      <button mat-raised-button  color="primary" [disabled]="!login.valid"
       type="submit">Iniciar sesión</button>
      </div>
      <div class="form-group mt-4">
    
      </div>
    </form>


     
      <div class="text-center">
      <button mat-raised-button color="accent" (click)="recuperarPass()" >Olvidaste  tu contraseña?</button>
      </div>

      <div class="p-3">
        <h6>¿No tenes cuenta?</h6> <button mat-stroked-button color="primary" [routerLink]="['/registro']">Registrate</button>
      </div>

      </div>
      
      
      </div>
     
    
    
      
    </mat-card>
    
