import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { ComercioComponent } from '../../usuario/perfil/comercio/comercio.component';

import { MatDialog } from '@angular/material/dialog';
import { TitleService } from '../../services/title.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {


  user = this.userService.userInfo;
  public usuario;



  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, public userService:UserService,
    public dialog: MatDialog, private _title:TitleService ) { }

  ngOnInit() {  

  }

  up() {
    window.scrollBy(0,-window.innerHeight);    
  }


  openDialogAyuda() {
    this.userService.eventoAnalytics('como_comparar')
    const dialogRef = this.dialog.open(Ayuda, {width:'700px'});

    dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    });
  }

  openDialogContacto() {
    this.userService.eventoAnalytics('contacto')

    const dialogRef = this.dialog.open(Contacto, {width:'500px'});

    dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    });
  }


}


@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.html',
})
export class Ayuda {}

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.html',
})
export class Contacto {}



