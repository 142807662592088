import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {


  
  constructor( public user: UserService) { }

  ngOnInit() {
  }

  registroComercio(form:NgForm){
    const f = form.value;
    if(form.invalid){
      alert('Por favor completa todos los campos para confirmar el registro');
       return;
      }else{
        this.user.SignUp(f)
      }
    } 



}
