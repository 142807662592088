import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipes'
})
export class PipesPipe implements PipeTransform {

  transform(value: any, arg?: string): any {
    const resultProductos = [];

    for (const producto of value) {

      if (producto.data.material[0] == arg) {
        resultProductos.push(producto);
      }
    }
    return resultProductos;
  }

}
