import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './compartido/home/home.component';
import { RegistroComponent } from './compartido/registro/registro.component';
import { LoginComponent } from './compartido/login/login.component';
import { NosotrosComponent } from './compartido/nosotros/nosotros.component';
import { VerificarEmailComponent } from './compartido/registro/verificar-email.component';
import { ImagenesComponent } from './compartido/imagenes/imagenes.component';


const routes: Routes = [

  {path: 'home', component: HomeComponent },
  {path: 'registro', component: RegistroComponent },
  {path: 'login', component: LoginComponent },
  {path: 'nosotros', component: NosotrosComponent },
  {path: 'imagenes', component: ImagenesComponent },
  {path: 'verificarEmail', component: VerificarEmailComponent },

  {
    path: 'usuario', 
    loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule) 
  },

  {
    path: 'tienda',
    loadChildren: () => import('./producto/producto.module').then(m => m.ProductoModule)
  }, 

  {path: '', component: HomeComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
