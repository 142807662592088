import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { DocumentData, DocumentReference, Firestore, doc, docData, updateDoc, setDoc } from '@angular/fire/firestore';
import { Auth, GoogleAuthProvider, signInWithEmailAndPassword, onAuthStateChanged,signOut,authState,
   createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from '@angular/fire/auth';
import { Analytics, logEvent } from '@angular/fire/analytics';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userData: any; // Save logged in user data
  public cliente;
  public clienteId;


  public userInfo: any;

  constructor(
    public afs: Firestore,   // Inject Firestore service
    public afAuth: Auth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,     // NgZone service to remove outside scope warning
    private _location: Location,
    private analytics: Analytics

  ) {

    //   onAuthStateChanged(this.afAuth, (user) => {
    //     if (user) {
    //      this.userData = user;
    //      this.sesion(this.userData.uid).subscribe((data) => {
    //       this.userInfo = data;
    //       this.clienteId = this.userInfo.clienteId || null
    //       if(this.clienteId) {
    //         this.getCliente(this.clienteId).subscribe((data) => {
    //           this.cliente = data;            
    //         })
    //       }else{
    //         this.cliente={userType:'comercio'}
    //       }

    //     });
    //     } else {
    //       this.userInfo = null;
    //     }
    //  });

     authState(this.afAuth).subscribe((user) => {
      if (user) {
       this.userData = user;
       this.sesion(this.userData.uid).subscribe((data) => {
        this.userInfo = data;
        this.clienteId = this.userInfo.clienteId || null
        if(this.clienteId) {
          this.getCliente(this.clienteId).subscribe((data) => {
            this.cliente = data;            
          })
        }else{
          this.cliente={userType:'comercio'}
        }

      });
      } else {
        this.userInfo = null;
        this.userData = null;
      }
    });
  
   }


  public sesion (id) {    
    const ref = doc(this.afs, 'users/' + id);
    return docData(ref);
   }


   // Sign in with email/password
  async SignIn(email, password) {
  return await signInWithEmailAndPassword(this.afAuth,email, password)
  .then((result) => {
   this.ngZone.run(() => {
      this.router.navigate(['home']);
    });

  }).catch((error) => {
    window.alert('Ops! =( Algo no ha salido bien!. Verifica el error y vuelve a intentar ' + ' / ' + 'Mensaje de Error: ' + '  ' + error.message);
    });
  }
  // Sign up with email/password
  async SignUp(form) {
  return await createUserWithEmailAndPassword(this.afAuth,form.mail, form.pass)
  .then((result) => {
     this.SetUserData(result.user,form);
     this.SignIn(form.mail,form.pass)
    //  this.SendVerificationMail(form.mail);
      this.router.navigate(['verificarEmail']);

  }).catch((error) => {
  window.alert('Ops! =( Algo no ha salido bien!. Verifica el error y vuelve a intentar ' + ' / ' + 'Mensaje de Error: ' + error.message);
  });
  }
 
  // Reset Forggot password
  async ForgotPassword(passwordResetEmail) {
  return await sendPasswordResetEmail(this.afAuth,passwordResetEmail)
  .then(() => {
  window.alert('El restablecimiento de tu contraseña se envio por correo, verifique su casilla.' + passwordResetEmail);
  }).catch((error) => {
  window.alert('El mail ingresado no coincide con nignun registro de nuestra base, vuelva a intentarlo. Si los problemas persisten es posible que deba registrarse primero.')
  });
  }
  // Returns true when user is looged in and email is verified
   isLoggedIn(): boolean {
  return (this.userInfo && this.userData ) ? true : false;
  }
  
  SetUserData(user,form) {
  const userRef: DocumentReference = doc(this.afs,`users/${user.uid}`);

  if(!userRef) {
  const userData = {
  uid: user.uid,
  email: user.email,
  displayName: form.nombre,
  photoURL: user.photoURL,
  emailVerified: user.emailVerified,
  userType: 'comercio',
  razon: form.razon,
  telefono: form.tel,
  provincia: form.provincia,
  localidad:form.localidad || '',
  cuit:form.partA + form.Dni + form.partB,
  calle: form.calle,
  altura: form.altura,
  piso: form.piso || '',
  cp:form.cp || '',
  createId:new Date()
  };
  return setDoc(userRef,userData, {
  merge: true
  });
  } else {
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: form.nombre,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      userType: 'comercio',
      razon: form.razon,
      telefono: form.tel,
      provincia: form.provincia,
      localidad:form.localidad || '',
      cuit:form.partA + form.dni + form.partB,
      calle: form.calle,
      altura: form.altura,
      piso: form.piso || '',
      cp:form.cp || '',
      createId:new Date()
  
      };
      return setDoc(userRef, userData, {
      merge: true
      });

  }
}
  // Sign out
  async SignOut() {
  return await signOut(this.afAuth).then(() => {
  // this.userInfo = null;
  // sessionStorage.clear();
  this.router.navigate(['login']);
  });
  }



async editarDatos(datos){
const ref = doc(this.afs, 'users/' + this.userData.uid);
return await updateDoc(ref, datos);
}

getCliente(cid:string){
  const ref = doc(this.afs, 'clientes/' + cid);
  return docData(ref);
}


eventoAnalytics(evento){
  // this.analytics.logEvent(evento);
  logEvent(this.analytics, evento);
}


}
